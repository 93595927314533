import { styled, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export const Headline = styled(Typography)(() => ({
  fontSize: '20px',
}))

export const Series = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

export const CardDate = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    left: theme.spacing(2),
  },
}))

export const HR = styled('hr')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.grey.light}`,

  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '&.hide-mobile': {
      display: 'none',
    },
  },
}))

export const ContactInfoItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const NamePicTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  [theme.breakpoints.down(300)]: {
    '& div': {
      flexDirection: 'column',
    },
  },
}))

export const ProfileCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: theme.spacing(2),
  borderRadius: '100%',

  '& img': {
    height: '200px',
    width: '200px',
    border: '2px solid white',
    borderRadius: '100%',
    objectFit: 'cover',
    objectPosition: 'top',

    [theme.breakpoints.down('sm')]: {
      height: '64px',
      width: '64px',
      border: '2px solid #DBD2C1',
      boxShadow: '0 0 0 2px white',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
    width: '0%',
    marginRight: 0,
    marginLeft: 0,
  },
}))

export const ProfileLetters = styled(Box)(({ theme }) => ({
  padding: 'calc(150px / 2 - 4rem / 2)',
  // 150px / 2 is the padding to fill half of the 150px width
  // 4 rem / 2 is half of the initials' width set below
  color: '#ffffff',
  backgroundColor: theme.palette.grey.light,
  borderRadius: '50%',
  boxShadow: '0px 0px 1px 1px rgb(0 0 0 / 15%)',
  border: '3px solid #ffffff',
  '& h1': {
    display: 'flex',
    width: '4rem',
    height: '4rem',
    fontSize: '3.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    letterSpacing: '5px',
  },
}))

export const CrestImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(-3.5),

    '& p': {
      marginRight: 0,
      marginTop: theme.spacing(1),
    },
  },
}))

export const ContactInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    marginRight: theme.spacing(2),
    color: 'black',
  },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    '& div': {
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),

      '& svg': {
        marginRight: '4px',
      },
    },
  },
}))

export const TitleRoundedBG = styled(Typography)(({ theme, color }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  fontSize: '0.75rem',
  lineHeight: '2',
  borderRadius: '1rem',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: '#FFFFFF',
  backgroundColor: color,
}))

export const BioWrapper = styled(Box)(({ theme }) => ({
  color: 'black',

  '& .bioFigures': {
    marginRight: theme.spacing(2),
    '& span:first-child': {
      color: theme.palette.grey.dark,
    },
  },
}))

export const SocialIcon = styled('a')(({ theme }) => ({
  display: 'inline-flex',
  width: '24px',
  height: '24px',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.grey.light,
  borderRadius: '100%',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: theme.palette.grey.dark,
  },
}))

export const FollowersAndSocial = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const FollowersWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'calc(100% + 32px)',
  marginLeft: '-16px',
  padding: theme.spacing(2),
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: 'transparent',

  [theme.breakpoints.up('sm')]: {
    width: 'calc(100% + 48px)',
    marginLeft: '-24px',
  },

  [theme.breakpoints.up('md')]: {
    width: 'inherit',
    marginLeft: '0',
    padding: theme.spacing(0),
    textAlign: 'left',
    backgroundColor: 'transparent',
  },
}))

export const SocialsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}))

export const FlexBox = styled(Box)(({ direction }) => ({
  display: 'flex',
  flexDirection: direction,
}))

export const useStyles = makeStyles(theme => ({
  outerContainer: {
    backgroundColor: 'white',
  },
  breadcrumbs: {
    marginTop: '6px',
  },
  innerContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  titleAndCrest: {
    marginTop: '1rem',
    width: '100%',
    justifyContent: 'space-between',
  },
  titleName: {
    fontSize: '3rem',
    color: 'black',
    lineHeight: '1.25',

    [theme.breakpoints.down('sm')]: {
      lineHeight: '40px',
      fontSize: '2rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  titleCrest: {
    whiteSpace: 'nowrap',
  },
  contactInfo: {
    alignItems: 'center',
  },
  bio: {
    marginBottom: '16px',
    '& p, & li': {
      whiteSpace: 'pre-wrap',
    },
    '& p, & ol, & ul': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    '& p:first-child': {
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
    '& li': {
      marginBottom: theme.spacing(4),
    },
    '& li p': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    '& blockquote': {
      marginLeft: 0,
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
    '& blockquote p:last-child': {
      marginBottom: 0,
    },
    '& > ul': {
      listStyleType: 'none',
      paddingLeft: 20,
    },
    '& > ul li': {
      position: 'relative',
    },
    '& > ul li::before': {
      content: "''",
      position: 'absolute',
      left: -20,
      top: 11,
      height: 5,
      width: 5,
      backgroundColor: theme.palette.primary.main,
      display: 'block',
      borderRadius: 100,
    },
    '& > ol': {
      listStyle: 'none',
      counterReset: 'level1',
      paddingLeft: 30,
    },
    '& > ol li': {
      position: 'relative',
    },
    '& > ol li:before': {
      content: "counter(level1) '.'",
      counterIncrement: 'level1',
      position: 'absolute',
      left: -30,
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
      fontSize: 16,
    },
    '& a': {
      textDecoration: 'none',
      boxShadow: `0 2px 0 ${theme.palette.primary.main}`,
      color: theme.palette.grey[600],
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: 'rgba(171,	145,	87, 0.25)',
        color: theme.palette.primary.dark,
      },
    },
  },
  bioAndVideo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  socialCount: {
    fontWeight: 'bold',
  },
  middleBanner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    backgroundColor: theme.palette.blue.light100,
    padding: '2.5rem 0.5rem',
  },
  contactButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      '& > div:first-of-type': {
        flexDirection: 'column',
        alignItems: 'center',
        '& > a': {
          width: '70%',
          marginLeft: 0,
          marginBottom: '1em',
        },
        '& > a:last-of-type': {
          marginBottom: 0,
        },
      },
    },
  },
  externalLinkIcon: {
    marginLeft: '1rem',
    fontSize: '1.5rem',
  },
  coachTitle: {
    [theme.breakpoints.only('xs')]: {
      fontWeight: 700,
      color: theme.palette.primary.dark,
      marginBottom: '0.5rem',
    },
  },
}))
